import React from 'react'
import Layout from './../../../components/help/Layout/Layout'
import Seo from '../../../components/help/Seo'
import Header from '../../../components/ui/Header/Header'
import HeaderFixed from './../../../components/ui/HeaderFixed/HeaderFixed'
import Intro from '../../../components/ui/Intro/Intro'
import Partnership from '../../../components/pages/Partnership/Partnership'
import PageNavigation from '../../../components/ui/PageNavigation/PageNavigation'
import { getHrefToHome } from '../../../utils/getHrefToHome'
import { graphql, useStaticQuery } from 'gatsby'

const Partnershipage = () => {
    const lang = 'en'

    const hrefHome = getHrefToHome(lang)

    const data = useStaticQuery(graphql`
        {
            titleOne: datoCmsRenshinPagePartnership(title: { eq: "Block 1: Title" }, locale: { eq: "en" }) {
                value
            }

            textOne: datoCmsRenshinPagePartnership(title: { eq: "Block 1: Text" }, locale: { eq: "en" }) {
                value
            }

            emailOne: datoCmsRenshinPagePartnership(title: { eq: "Block 1: E-mail" }, locale: { eq: "en" }) {
                value
            }

            titleTwo: datoCmsRenshinPagePartnership(title: { eq: "Block 2: Title" }, locale: { eq: "en" }) {
                value
            }

            textTwo: datoCmsRenshinPagePartnership(title: { eq: "Block 2: Text" }, locale: { eq: "en" }) {
                value
            }

            emailTwo: datoCmsRenshinPagePartnership(title: { eq: "Block 2: E-mail" }, locale: { eq: "en" }) {
                value
            }

            titleSmall: datoCmsRenshinPagePartnership(title: { eq: "Title small" }, locale: { eq: "en" }) {
                value
            }

            seo: datoCmsRenshinSeo(pageName: { eq: "Partnership" }, locale: { eq: "en" }) {
                titleH1
                crumbsName
                bgDesktop {
                    url
                }
                bgTabletA {
                    url
                }
                bgTabletP {
                    url
                }

                bgMobile {
                    url
                }
                footerLinks {
                    title
                    url
                }
                seo {
                    title
                    description
                }
            }

            main: datoCmsRenshinSeo(pageName: { eq: "Main" }, locale: { eq: "en" }) {
                crumbsName
            }
        }
    `)

    const photo = {
        1920: data.seo.bgDesktop.url,
        1440: data.seo.bgDesktop.url,
        1024: data.seo.bgTabletA?.url,
        768: data.seo.bgTabletP?.url,
        360: data.seo.bgMobile?.url
    }

    const breadCrumbs = [
        { name: `${data.main.crumbsName}`, href: `${hrefHome}`, current: false },
        { name: `${data.seo.crumbsName}`, current: true }
    ]

    return (
        <Layout lang={lang}>
            <Seo title={data.seo?.seo?.title} description={data.seo?.seo?.description} />
            <Header light lang={lang} />
            <HeaderFixed lang={lang} light />
            <Intro crumbs={breadCrumbs} title={`${data.seo.titleH1}`} photo={photo} lang={lang} />
            <Partnership data={data} lang={lang} />
            <PageNavigation links={data.seo.footerLinks} lang={lang} />
        </Layout>
    )
}

export default Partnershipage
